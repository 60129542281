import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Autumn Term 2024",
  "date": "2024-09-06",
  "author": "Andy B",
  "featuredImage": "kids-coding.jpeg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`CoderDojo will be back soon at the Bishops Cleeve Library!  Please note that we now have a slightly later start time - 1pm.`}</p>
    <p>{`Our dates for the Autumn term will be:`}</p>
    <ul>
      <li parentName="ul">{`14th, 28th Sept`}</li>
      <li parentName="ul">{`12th, 26th Oct`}</li>
      <li parentName="ul">{`9th, 23rd Nov`}</li>
      <li parentName="ul">{`7th Dec`}</li>
    </ul>
    <p>{`Spaces for the sessions in September and October can be booked now, in the `}<a parentName="p" {...{
        "href": "https://coderdojo.com/en/dojos/gb/cheltenham/bishops-cleeve-bishops-cleeve-library"
      }}>{`usual place`}</a>{`.`}</p>
    <p>{`We look forward to seeing you back to the library very soon!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      